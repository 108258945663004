#email-verification-page {

    .top-container {
        height: 230px;
        background-color: #340505;

        .header-container {
            padding: 15px;
            display: flex;
            justify-content: flex-end;

            .close-button {
                cursor: pointer;
                color: white;
            }
        }

        .verify-email-label {
            height: calc(100% - 54px - 30px);
            // padding-top: 10px;
            margin-bottom: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 600;
            color: white;
        }

        .email-logo-container {
            height: 132px;
            width: 132px;
            margin-left: auto;
            margin-right: auto;
            transform: translateY(-75px);
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid white;
            border-radius: 68px;
            box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12) !important;
            background-color: white;

            .email-logo {
                width: 78px;
            }
        }
    }

    .bottom-container {
        padding-left: 20px;
        padding-right: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .body-container {
            position: relative;
            top: 90px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            line-height: 1.4;

            .label-1 {
                padding-bottom: 15px;
                text-align: center;
                font-size: 18px;
                color: #7c7c7c;
            }

            .email-label {
                padding-bottom: 20px;
                font-size: 20px;
                font-weight: 600;
                color: #3a3a3a;
            }

            .resend-label {
                font-size: 16px;
                font-weight: 700;
                cursor: pointer;
                color: rgb(113, 13, 13);
            }
        }
    }
}