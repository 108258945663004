#leaderboard-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        max-width: 1000px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;

        .toggle-button-container {
            height: 30px;
            width: calc(100% - 40px);
            max-width: 450px;
            display: flex;
            justify-content: center;
            margin: 20px;
        }

        .leaderboard-embla {
            height: 100%;
            width: 100%;
            overflow-x: hidden;

            .embla__container {
                height: 100%;
                display: flex;

                .embla__slide {
                    flex: 0 0 100%;
                    min-width: 0;
                }
            }
        }

        // Override CSS
        .pull-to-refresh-container {
            height: calc(100% - 30px - 40px);
        }
    }
}

// Responsive Design
@media (min-width: 1000px) {
    #leaderboard-page {

        .max-width-container {
            border-left: 1px solid #e5e7eb;
            border-right: 1px solid #e5e7eb;
        }
    }
}