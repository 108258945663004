@import "../../global-style.scss";

#support-page {
    height: 100%;

    .max-width-container {
        height: calc(100% - 10px);
        width: 80%;
        max-width: 720px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        background-color: white;

        .padding-container {
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: white;

            .form-container {

                .form-field {
                    width: 100%;
                }
            }

            .more-description {
                padding-top: 20px;
                padding-bottom: 20px;
                line-height: 1.3;
                font: 500 0.9em Lato;
                color: #676767;
            }

            .additional-info-label {
                padding-top: 20px;
                padding-bottom: 20px;
                font: 500 1em Lato;
                color: #676767;
            }

            .upload-screenshot-container {
                height: 85px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                background-color: #f5f5f5;

                .preview-container {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    color: grey;

                    .upload-screenshot-image {
                        height: 100%;
                        max-width: 100px;
                        padding-right: 10px;
                    }

                    .upload-screenshot-details-container {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;

                        .upload-screenshot-name {
                            font: 600 1.1em Lato;
                            color: black;
                        }

                        .upload-screenshot-size {
                            font: 600 1em Lato;
                        }
                    }

                    .close-icon {
                        width: 50px;
                    }
                }

                .upload-screenshot-label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;

                    .upload-icon {
                        color: grey;
                    }

                    .upload-screenshot-text {
                        font: 600 1em Lato;
                        color: black;
                    }
                }
            }

            .send-button {
                height: 45px;
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                text-transform: none;
                text-decoration: none;
                cursor: pointer;
                font: 500 1.1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
                transition: all 0.5s;

                .button-spinner {
                    color: white;
                }
            }

            .disabled-button {
                border: 2px solid transparent;
                cursor: not-allowed;
                background-color: rgba(0, 0, 0, 0.12);
                color: rgba(0, 0, 0, 0.26);
            }
        }
    }
}

// Responsive Design
@media (min-width: 720px) {
    #support-page {

        .max-width-container {
            border-left: 1px solid #e5e7eb;
            border-right: 1px solid #e5e7eb;
        }
    }
}