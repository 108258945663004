@import "../../../global-style.scss";

#profile-edit-page {

    .max-width-container {
        max-width: 1000px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        background-color: white;

        .under-moderation-container {
            padding: 15px;
            margin: 10px;
            display: flex;
            border-radius: 10px;
            background-color: #F2F2F2;

            .left-container {
                display: flex;
                align-items: center;
                flex: 2;

                .moderation-icon-container {
                    height: 50px;
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    background-color: white;

                    .moderation-icon {
                        height: 35px;
                    }
                }
            }

            .right-container {
                display: flex;
                flex-direction: column;
                flex: 8;
                gap: 5px;

                .moderation-title {
                    font: 600 0.8em Lato;
                }

                .moderation-desc {
                    font: 400 0.8em Lato;
                    color: #676767;
                }
            }
        }

        .top-container {
            height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .profile-photo {
                height: 100px;
                width: 100px;
            }

            .username-container {
                display: flex;
                align-items: center;
                gap: 3px;

                .name {
                    font-size: 1.4em;
                    font-weight: bold;
                    color: rgb(51, 51, 51);
                }

                .premium-mini-badge {
                    height: 15px;
                }
            }

            .preview-button {
                cursor: pointer;
                font: 600 1em Lato;
                color: $sugarbook-main-color;
            }
        }

        .tab-container {

            .embla {
                height: 100%;
                width: 100%;
                overflow: hidden;
                // overflow-x: hidden;

                .embla__container {
                    height: 100%;
                    display: flex;
                    // align-items: flex-start;
                    transition: height 0.2s;

                    .embla__slide {
                        flex: 0 0 100%;
                        min-width: 0;
                    }
                }
            }
        }
    }
}