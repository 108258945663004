#forgot-password-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;

        .forgot-password-label {
            font: 600 1.7em Lato;
        }

        .email-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            .email-form-field {
                width: 100%;
            }
        }

        .button-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .reset-button {
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid rgb(113, 13, 13);
                border-radius: 25px;
                text-transform: none;
                font-size: 1.1em;
                font-weight: 500;
                background-color: rgb(113, 13, 13);
                color: white;
                transition: all 0.5s;

                .button-spinner {
                    color: white;
                }
            }
        }
    }

    // Utility CSS
    .disabled-button {
        border: 2px solid transparent !important;
        cursor: not-allowed !important;
        background-color: rgba(0, 0, 0, 0.12) !important;
        color: rgba(0, 0, 0, 0.26) !important;
    }

    .inactive-button {
        background-color: rgba(0, 0, 0, .12) !important;
        border-color: transparent !important;
        color: rgba(0, 0, 0, .26) !important;
    }

    .active-button {
        background-color: rgb(113, 13, 13) !important;
        border-color: rgb(113, 13, 13) !important;
        color: white !important;
    }
}