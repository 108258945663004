#leaderboard-events-dialog {
    height: 75vh;

    .dialog-header {
        padding: 15px;
        display: flex;
        align-items: center;

        .left-container {
            display: flex;
            align-items: center;
            flex: 1;

            .back-icon {
                font-size: 30px;
            }
        }

        .middle-container {
            flex: 10;
            text-align: center;
            font: 600 1.2em Lato;
        }

        .right-container {
            flex: 1;
        }
    }

    .events-container {
        height: calc(100% - 60px);
        overflow: auto;
    }
}

// Utility CSS
.min-leaderboard-events-width {
    min-width: 500px;
}