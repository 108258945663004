#leaderboard-casino-tab-subcomponent {
    height: 100%;
    position: relative;

    #casino-tab-lottie-container {
        width: 100%;
        position: absolute;
        top: 175px;
        display: flex;
        justify-content: center;

        .lottie-center-container {
            width: 100%;
            max-width: 300px;
        }
    }

    .tab-container {
        height: 100%;

        .embla {
            height: calc(100% - 48px);
            width: 100%;
            overflow-x: hidden;

            .embla__container {
                height: 100%;
                display: flex;

                .embla__slide {
                    flex: 0 0 100%;
                    min-width: 0;
                }
            }
        }
    }
}