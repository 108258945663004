@import "../../../global-style.scss";

#upgrade-checkout-page {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F4F4F4;

    .max-width-container {
        max-width: 500px;

        .padding-container {
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .featured-container {}

            .discretion-container {
                padding: 15px 10px;
                display: flex;
                border-radius: 5px;
                background-color: white;

                .credit-card-icon-container {
                    width: 50px;
                    display: flex;
                    align-items: center;

                    .credit-card-icon {
                        width: 40px;
                    }
                }

                .discretion-description {
                    width: calc(100% - 50px);
                    padding-left: 10px;

                    .discretion-description-head {
                        font: 400 1em Lato;
                    }

                    .discretion-description-body {
                        font: 600 1em Lato;
                    }
                }
            }

            .credit-card-note-container {
                padding: 15px 10px;
                display: flex;
                border-radius: 5px;
                background-color: white;

                .world-icon-container {
                    width: 50px;
                    display: flex;
                    align-items: center;

                    .world-icon {
                        width: 40px;
                    }
                }

                .credit-card-note-description {
                    width: calc(100% - 50px);
                    padding-left: 10px;
                    font: 500 1em Lato;
                    color: #333333;
                }
            }

            .upgrade-package-container {
                padding: 15px 10px;
                display: flex;
                border-radius: 10px;
                box-shadow: 0 0 4px 0 rgb(194 194 194 / 50%);
                background-color: white;

                .premium-icon-container {
                    width: 50px;
                    display: flex;
                    align-items: center;

                    .premium-icon {
                        width: 50px;
                    }
                }

                .upgrade-package-description {
                    width: calc(100% - 50px);
                    padding-left: 10px;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    .months-label {
                        font: 600 1.1em Lato;
                    }

                    .price-label {
                        font: 500 1.2em Lato;
                        color: #676767;
                    }
                }
            }

            .subscription-renew-date {
                text-align: center;
                font: 500 0.8em Lato;
                color: #7C7C7C;
            }

            .total-container {
                padding-top: 10px;
                padding-right: 10px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 20px;

                .total-label {
                    font: 400 1em Lato;
                }

                .total-amount {
                    font: 600 1.1em Lato;
                }
            }

            .continue-button {
                height: 50px;
                width: 100%;
                margin: 20px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1.3em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .billed-info {
                padding: 0 10px 15px 10px;
                text-align: center;
                font: 500 0.8em Lato;
                color: #7C7C7C;

                .doublesize {
                    font: 600 1.2em Lato;
                }
            }

            .cancellation-info {
                padding: 0 0 15px 0;
                text-align: center;
                font: 500 0.8em Lato;
                color: #7C7C7C;
            }
        }
    }
}