@import "../../../../global-style.scss";

#upgrade-upgrade-plans-subcomponent {

    .max-width-container {
        max-width: 1000px;
        margin: 0 auto;

        .padding-container {
            padding: 30px 10px 20px 10px;

            .plans-container {
                display: flex;
                gap: 10px;
                cursor: pointer;

                .plan-container {
                    position: relative;
                    margin: 4px;
                    padding: 15px 0 10px 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 5px;
                    flex: 1;
                    border-radius: 5px;
                    background-color: white;

                    .most-popular-tag {
                        position: absolute;
                        top: -14px;
                        padding: 7px 10px;
                        border-radius: 25px;
                        font: 500 0.5em Lato;
                        background-color: $sugarbook-main-color;
                        color: white;
                    }

                    .save-percentage-label {
                        width: 100%;
                        padding: 5px 0;
                        text-align: center;
                        text-transform: uppercase;
                        font: 600 1em Lato;
                        background: linear-gradient(90deg, rgba(2, 0, 36, 0) 0%, rgba(0, 255, 71, 1) 50%, rgba(2, 0, 36, 0) 100%);
                    }

                    .number-of-months {
                        font: 500 2.3em Lato;
                    }

                    .months-label {
                        padding-bottom: 23px;
                        color: #676767;
                    }

                    .discounted-price {
                        font: 700 1.1em Lato;
                    }

                    .original-price {
                        text-decoration: line-through;
                        font: 600 0.9em Lato;
                        color: $sugarbook-main-color;
                    }

                    .n-month-text {
                        font: 400 0.7em Lato;
                        color: #676767;
                    }

                    .save-container {
                        width: calc(100% - 10px);
                        margin-top: 20px;
                        padding: 15px 5px 10px 5px;
                        position: relative;
                        background-color: #0bec2f;

                        .bonus-container {
                            width: calc(100% - 10px);
                            position: absolute;
                            top: -10px;
                            display: flex;
                            justify-content: center;

                            .bonus-label {
                                padding: 3px 13px;
                                border-radius: 7px;
                                font: 600 0.7em Lato;
                                background-color: $sugarbook-main-color;
                                color: white;
                            }
                        }

                        .added-coins-value {
                            text-align: center;
                            font: 600 0.8em/1.3 Lato;

                            .coin-icon-container {
                                padding-right: 3px;

                                .coin-icon {
                                    height: 13px;
                                    position: relative;
                                    top: 1px;
                                }
                            }
                        }
                    }
                }

                // Utility CSS
                .selected-package {
                    margin: 0;
                    border: 4px solid $sugarbook-main-color;
                    background-color: rgba(113, 13, 13, 0.06);
                }
            }
        }
    }
}