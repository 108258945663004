#video-call-recent-tab-empty-list-subcomponent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .message-icon-container {

        .message-icon {
            width: 80px;
        }
    }

    .no-message-text {
        margin-top: 15px;
        margin-bottom: 30px;
        font-size: 1.2em;
        font-weight: 600;
    }

    .browse-member-button {
        height: 45px;
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        align-items: center;
        border-radius: 25px;
        text-decoration: none;
        font-size: 1.1em;
        font-weight: 500;
        background-color: #710d0d;
        color: white;
    }
}