@import "../../../global-style.scss";

#description-signup-page {
    height: 100%;

    .max-width-container {
        height: 90%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .steps-label {
            margin-bottom: 20px;
            text-align: center;
            font-size: 16px;
            color: #7c7c7c;
        }

        .step-description-label {
            padding-bottom: 20px;
            text-align: center;
            font-size: 26px;
            font-weight: 700;
        }

        .about-me-form-field-container {
            height: 170px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            .about-me-form-field {
                width: 100%;
            }

            .character-limit-label {
                text-align: right;
            }
        }

        .looking-for-form-field-container {
            height: 170px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            .looking-for-form-field {
                width: 100%;
            }

            .character-limit-label {
                text-align: right;
            }
        }

        .button-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .next-button {
                height: 45px;
                width: 100%;
                margin-top: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                text-decoration: none;
                text-transform: capitalize;
                font: 500 1.1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
                transition: all 0.5s;
            }

            .disabled-button {
                cursor: not-allowed;
                background-color: rgba(0, 0, 0, 0.12);
                color: rgba(0, 0, 0, 0.26);
            }

            .skip-button {
                text-decoration: none;
                text-transform: capitalize;
                font: 500 0.8em Lato;
                color: rgb(124, 124, 124);
            }
        }
    }
}