@import "../../../../global-style.scss";

#diamonds-withdrawal-info-dialog {
    margin-top: auto;

    .dialog-header {
        padding: 20px 20px 0 20px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
            }
        }
    }

    .padding-container {
        padding: 0 30px 30px 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .header {
            font: 600 1.7em Lato;
        }

        .subheader {
            padding-top: 25px;
            font: 600 1em Lato;
        }

        .text {
            padding-top: 5px;
            font: 500 0.9em Lato;
        }

        .close-button {
            height: 45px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            text-transform: none;
            text-decoration: none;
            font: 500 1.1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }
}

// Utility CSS
.diamonds-withdrawal-max-width-container {
    max-width: 300px;
}