@import "../../../global-style.scss";

#info-signup-page {
    height: 100%;

    .max-width-container {
        height: 90%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .started-label {
            padding-bottom: 50px;
            text-align: center;
            font-size: 26px;
            font-weight: 700;
        }

        .profile-img-container {
            margin-bottom: 20px;
            display: flex;
            justify-content: center;

            .profile-img {}
        }

        .profile-img-button {
            margin-bottom: 20px;
            position: relative;
            display: flex;
            justify-content: center;

            .profile-img {
                height: 133px;
                width: 133px;
                border-radius: 70px;
                object-fit: cover;
            }

            .add-icon-container {
                height: 30px;
                width: 30px;
                position: absolute;
                bottom: 10px;
                margin-left: 90px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                background-color: $sugarbook-main-color;

                .add-icon {
                    font-size: 20px;
                    color: white;
                }
            }
        }

        .description {
            margin-bottom: 30px;
            text-align: center;
        }

        .name-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 25px;
            margin-left: auto;
            margin-right: auto;

            .name-form-field {
                width: 100%;
            }
        }

        .dob-form-field-container {
            // height: 30px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.42);

            .birthday-label {
                font: 500 0.8em Lato;
                color: rgba(0, 0, 0, 0.6);
            }

            .dob-form-field {
                width: 100%;
            }

            .react-date-picker {
                width: 100%;

                .react-date-picker__wrapper {
                    border: none;

                    .react-date-picker__inputGroup {
                        pointer-events: none;
                    }
                }
            }

            .react-calendar {
                border: none;
                border-radius: 5px;
                box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);

                button {
                    height: 50px;
                    width: 50px;
                    border-radius: 50px;
                }

                .react-calendar__tile--active {
                    background-color: $sugarbook-main-color;
                }
            }
        }

        .location-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 20px;
            margin-left: auto;
            margin-right: auto;

            .autocomplete-wrapper {
                width: 100%;

                .form-field {
                    width: 100%;
                }
            }
        }

        .continue-button {
            height: 47px;
            margin-top: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            text-decoration: none;
            text-transform: capitalize;
            cursor: pointer;
            font: 500 1.3em Lato;
            background-color: rgb(113, 13, 13);
            color: white;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26);
        }
    }
}