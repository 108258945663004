#livestream-interactable-overlay-subcomponent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 20;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0) 100%) !important;

    .top-section {

        .padding-container {
            // margin-top: 25px;
            padding: 10px 10px 0 10px;
        }
    }

    .bottom-section {}

    // .swipeable-container {
    //     height: 100%;

    //     .react-swipeable-view-container {
    //         height: 100%;

    //         .blank-container {
    //             padding: 35px 20px;
    //             display: flex;
    //             justify-content: flex-end;

    //             .back-button-container {
    //                 height: 36px;
    //                 width: 36px;
    //                 display: flex;
    //                 justify-content: center;
    //                 align-items: center;

    //                 .back-button {
    //                     height: 12px;
    //                 }
    //             }
    //         }

    //         .interactable-container {
    //             height: 100%;
    //             display: flex;
    //             flex-direction: column;
    //             justify-content: space-between;


    //         }
    //     }
    // }

    // Utility CSS
    .extra-top-padding {
        padding-top: 25px; // remove padding if there is banner
    }
}