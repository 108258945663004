@import "../../../../global-style.scss";

#save-to-homescreen-dialog {
    height: 100%;
    background-color: #333333;

    .header {
        padding: 20px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
                color: white;
            }
        }
    }

    .pwa-background-container {
        width: 100%;

        .pwa-background {
            width: 100%;
            position: relative;
            right: 10px;
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .try-now-button {
        height: 50px;
        width: 250px;
        margin: 15px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        cursor: pointer;
        font: 600 1.3em Lato;
        background-color: $sugarbook-main-color;
        color: white;
    }
}