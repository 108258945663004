#emoji-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
}

.animate-fly-up-1 {
    -webkit-animation: xAxis1 1.3s 0s infinite;
    -moz-animation: xAxis1 1.3s 0s infinite;
    -o-animation: xAxis1 1.3s 0s infinite;
    -ms-animation: xAxis1 1.3s 0s infinite;
    animation: xAxis1 1.3s 0s infinite;
    animation-timing-function: ease-in;
}

.animate-fly-up-2 {
    -webkit-animation: xAxis2 1.8s 0s infinite;
    -moz-animation: xAxis2 1.8s 0s infinite;
    -o-animation: xAxis2 1.8s 0s infinite;
    -ms-animation: xAxis2 1.8s 0s infinite;
    animation: xAxis2 1.8s 0s infinite;
    animation-timing-function: ease-in;
}

.animate-fly-up-3 {
    -webkit-animation: xAxis3 3s 0s infinite;
    -moz-animation: xAxis3 3s 0s infinite;
    -o-animation: xAxis3 3s 0s infinite;
    -ms-animation: xAxis3 3s 0s infinite;
    animation: xAxis3 3s 0s infinite;
    animation-timing-function: ease-in;
}

.animate-fly-up-4 {
    -webkit-animation: xAxis4 1.2s 0s infinite;
    -moz-animation: xAxis4 1.2s 0s infinite;
    -o-animation: xAxis4 1.2s 0s infinite;
    -ms-animation: xAxis4 1.2s 0s infinite;
    animation: xAxis4 1.2s 0s infinite;
    animation-timing-function: ease-in;
}

.animate-fly-up-5 {
    -webkit-animation: xAxis5 2.2s 0s infinite;
    -moz-animation: xAxis5 2.2s 0s infinite;
    -o-animation: xAxis5 2.2s 0s infinite;
    -ms-animation: xAxis5 2.2s 0s infinite;
    animation: xAxis5 2.2s 0s infinite;
    animation-timing-function: ease-in;
}

.animate-fly-up-1::after,
.animate-fly-up-2::after,
.animate-fly-up-3::after,
.animate-fly-up-4::after,
.animate-fly-up-5::after {
    content: var(--content-data);
    display: block;
    -webkit-animation: yAxis 2.5s 0s 1;
    -moz-animation: yAxis 2.5s 0s 1;
    -o-animation: yAxis 2.5s 0s 1;
    -ms-animation: yAxis 2.5s 0s 1;
    animation: yAxis 2.5s 0s 1;
    animation-timing-function: linear;
}

@keyframes xAxis1 {
    50% {
        animation-timing-function: cubic-bezier(0.37, 0, 0.63, 1);
        transform: translateX(10px);
    }
}

@keyframes xAxis2 {
    50% {
        animation-timing-function: cubic-bezier(0.37, 0, 0.63, 1);
        transform: translateX(8px);
    }
}

@keyframes xAxis3 {
    50% {
        animation-timing-function: cubic-bezier(0.37, 0, 0.63, 1);
        transform: translateX(12px);
    }
}

@keyframes xAxis4 {
    50% {
        animation-timing-function: cubic-bezier(0.37, 0, 0.63, 1);
        transform: translateX(13px);
    }
}

@keyframes xAxis5 {
    50% {
        animation-timing-function: cubic-bezier(0.37, 0, 0.63, 1);
        transform: translateX(11px);
    }
}

@keyframes yAxis {
    0% {
        opacity: 0;
        transform: scale(0.3);
    }

    5% {
        opacity: 0.9;
        transform: scale(1.2);
    }

    /* 30% {
      transform: translateY(-15vh);
      transform: scale(0.7);
    } */
    100% {
        opacity: 0;
        transform: translateY(-50vh) scale(0.5);
    }
}

.emoji-positioning {
    position: absolute;
    bottom: 14vh;
    // bottom: 0;
    right: 1.25em;
    font-size: 2.25rem;
    line-height: 2.5rem;
}