#livestream-gift-alert-overlay-subcomponent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;

    .padding-container {
        height: calc(70% - 300px);
        padding-top: 300px;

        .gift-element-container {
            width: fit-content;
            max-width: 90%;
            margin: 0 0 10px 5%;
            padding-left: 10px;
            display: flex;
            border-radius: 50px;
            -webkit-animation: slideInAndOut 3s forwards;
            -moz-animation: slideInAndOut 3s forwards;
            animation: slideInAndOut 3s ease-in-out;

            .profile-picture-container {
                width: 45px;
                display: flex;
                align-items: center;

                .profile-picture {
                    height: 30px;
                    width: 30px;
                }
            }

            .chat-info-container {
                width: fit-content;
                max-width: calc(100% - 45px - 50px);
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .user-info-container {
                    display: flex;
                    align-items: center;

                    .username {
                        font: 700 1em Lato;
                        color: #FFE18C;
                    }

                    .levelling-badge-container {
                        padding-left: 5px;
                        display: flex;

                        .levelling-badge {
                            height: 13px;
                        }
                    }

                    .sent-text {
                        padding-left: 5px;
                        font: 600 0.9em Lato;
                        color: #FFE18C;
                    }
                }

                .gift-name {
                    font: 600 0.9em Lato;
                    color: #FFE18C;
                }

                .gift-diamond-count {
                    display: flex;
                    gap: 2px;
                    font: 600 0.9em Lato;
                    color: #00ff75;

                    .diamond-icon-container {
                        display: flex;
                        align-items: center;

                        .diamond-icon {
                            height: 13px;
                        }
                    }
                }
            }

            .gift-icon-container {
                width: 50px;
                padding-left: 10px;
                padding-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                .gift-icon {
                    height: 50px;
                }
            }
        }
    }
}

// Utility CSS
.gift-element-gradient-1 {
    background: linear-gradient(90deg, #FF0505 -2.66%, rgba(155, 6, 6, 0) 75%);
}

.gift-element-gradient-2 {
    background: linear-gradient(90deg, #05FF00 -2.62%, rgba(24, 73, 2, 0) 75%);
}

.gift-element-gradient-3 {
    background: linear-gradient(90deg, rgba(0, 196, 184, 1) 13.72%, rgba(0, 97, 187, 0) 75%);
}

.gift-element-gradient-4 {
    background: linear-gradient(90deg, #DB00FF -2.5%, rgba(13, 9, 255, 0) 75%);
}

.gift-element-gradient-5 {
    background: linear-gradient(90deg, rgba(196, 152, 0, 1) 13.75%, rgba(255, 28, 28, 0) 75%);
}

.gift-element-gradient-6 {
    background: linear-gradient(90deg, #EC0991 -2.66%, rgba(255, 0, 0, 0) 75%);
}

.gift-element-gradient-7 {
    background: linear-gradient(90deg, #2155A8 -2.6%, rgba(235, 197, 0, 0) 75%);
}

.gift-element-gradient-8 {
    background: linear-gradient(90deg, #FF9394 13.73%, rgba(187, 168, 0, 0) 75%);
}

.gift-element-gradient-9 {
    background: linear-gradient(90deg, #D9004E -2.5%, rgba(194, 0, 12, 0) 75%);
}

.gift-element-gradient-10 {
    background: linear-gradient(90deg, rgba(0, 196, 149, 1) 13.75%, rgba(237, 255, 28, 0) 75%);
}