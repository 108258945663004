#edit-profile-personal-information-subcomponent {

    .personal-information-label {
        height: 50px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        font: 500 1.1em Lato;
        background-color: #fafafa;
    }

    .form-field-container {
        height: 50px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;

        .label-container {
            display: flex;
            flex: 1;

            .label {
                color: #919191;
            }

            .info-icon {
                padding-left: 10px;
                font-size: 19px;
            }

            // Utility CSS
            .incomplete-field {
                color: red;
            }
        }

        .output {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
        }

        .toggle {
            display: flex;
            justify-content: flex-end;
            flex: 1;
        }

        .form-container {
            display: flex;
            flex: 2;

            .dropdown-field {
                width: 100%;
                padding-left: 30px;
                padding-right: 15px;
            }
        }

        .input-container {
            display: flex;
            flex: 2;

            .form-field {
                width: 100%;
                padding-top: 3px;
                padding-left: 30px;
                padding-right: 15px;
            }
        }
    }
}