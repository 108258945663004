#gift-animation-overlay-subcomponent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;

    #gift-animation-video-player {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}