#chat-section-emoji-element-subcomponent {
    padding-left: 15px;
    display: flex;

    .profile-picture-container {
        width: 45px;
        display: flex;
        align-items: center;

        .profile-picture {
            height: 30px;
            width: 30px;
        }
    }

    .chat-info-container {
        width: 88%;
        display: flex;
        align-items: center;
        gap: 5px;

        .livestreaming-achievement-badge-container {
            display: flex;
            align-items: center;
            gap: 3px;

            .livestreaming-achievement-badge {
                height: 13px;
            }
        }

        .username {
            font: 700 0.9em Lato;
        }

        .levelling-badge-container {
            padding-top: 3px;
            display: flex;

            .levelling-badge {
                height: 13px;
            }
        }

        .emoji-icon-container {
            font: 700 0.9em Lato;
        }
    }
}

// Utility CSS
.yellow {
    color: #FFE18C;
}