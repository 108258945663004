#topbar-action-buttons-subcomponent {
    // width: 90px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    .charged-message-button-container {
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 50;
        cursor: pointer;

        .charged-message-button {
            height: 27px;
        }
    }

    .reset-camera-microphone-button-container {
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .reset-camera-microphone-button {
            // height: 20px;
            font-size: 30px;
            color: white;
        }
    }

    .disabled-reset-button {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .kick-caller-button-container {
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .kick-caller-button {
            height: 24px;
        }
    }

    .end-call-button-container {
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 50;
        cursor: pointer;

        .end-call-button {
            height: 40px;
        }
    }

    .more-button-container {
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 50;
        cursor: pointer;

        .more-icon {
            height: 20px;
            color: white;
        }
    }
}

// MUI Menu CSS
.video-call-menu {

    .video-call-action-menu-item {
        height: 50px;

        .reset-camera-microphone-button-container {
            height: 36px;
            width: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .reset-camera-microphone-button {
                // height: 20px;
                font-size: 30px;
                color: white;
            }
        }

        .camera-settings-button-container {
            display: flex;
            justify-content: center;

            .camera-settings-icon {
                color: white;
            }
        }

        .disabled-reset-button {
            cursor: not-allowed;
            opacity: 0.5;
        }

        .video-call-menu-text {
            color: white;
        }
    }
}