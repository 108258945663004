@import "../../../../../global-style.scss";

#tor-filter-shared-component {

    .dialog-header {
        padding: 20px 20px 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .subheader {
            display: flex;
            gap: 5px;
            font: 600 1.1em Lato;

            .new-label {
                padding: 3px 8px;
                border-radius: 25px;
                font: 600 0.7em Lato;
                background-color: red;
                color: white;
            }
        }

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
            }
        }
    }

    .filter-padding-container {
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .select-tor-description {
            font: 500 0.8em Lato;

            .max-tor-color {
                font-weight: bold;
                color: $sugarbook-main-color;
            }
        }

        .tags-container {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .tag-container {
                position: relative;
                cursor: pointer;

                .tag-label {}

                .blur-tag {
                    filter: blur(3px);
                }

                .premium-lock-container {
                    position: absolute;
                    bottom: -10px;
                    right: -3px;
                }
            }

            .selected-tag {
                background-color: #f4ecec;
            }
        }

        .tag-details {
            height: 80px;
            min-height: 40px;
            padding: 12px 24px;
            border-radius: 12px;
            font-size: 14px;
            line-height: 1.4;
            background-color: #F6F6F6;
        }

        .see-all-terms-container {
            display: flex;
            align-items: center;
            cursor: pointer;

            .see-all-terms-label {
                font: 600 0.9em Lato;
                color: $sugarbook-main-color;
            }

            .right-icon {
                font-size: 22px;
                color: #CCCCCC;
            }
        }

        .done-button {
            width: fit-content;
            margin: auto;
            padding: 10px 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            cursor: pointer;
            font: 600 1em Lato;
            background: $sugarbook-main-color;
            color: white;
        }
    }
}