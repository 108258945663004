@import "../../../../global-style.scss";

#confirm-app-reset-dialog {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 40px;

        .are-you-sure-title {
            text-align: center;
            font: 600 1.4em Lato;
        }

        .confirm-app-reset-description {
            text-align: center;
            font: 500 1em Lato;
        }

        .buttons-container {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .confirm-button {
                padding: 10px 20px;
                border-radius: 50px;
                text-align: center;
                font: 600 1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .cancel-button {
                padding: 10px 20px;
                border-radius: 50px;
                text-align: center;
                font: 600 1em Lato;
                color: $sugarbook-main-color;
            }
        }
    }
}