#leaderboard-one-on-one-tab-subcomponent {
    height: 100%;

    .tab-container {
        height: 100%;

        .leaderboard-private-call-tab-embla {
            height: calc(100% - 48px);
            width: 100%;
            overflow-x: hidden;

            .embla__container {
                height: 100%;
                display: flex;

                .embla__slide {
                    flex: 0 0 100%;
                    min-width: 0;
                }
            }
        }
    }
}