#interactable-overlay-tip-menu-subcomponent {
    position: absolute;
    right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 30;

    .tip-menu-header-container {
        padding: 5px 12px 5px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        cursor: pointer;
        background-color: rgb(113, 13, 13);

        .left-icon {
            color: white;
        }

        .tip-menu-label {
            padding-bottom: 1px;
            font: 600 0.9em Lato;
            color: white;
        }
    }

    .tip-menu-list-container {
        width: calc(200px - 5px);
        padding: 0 12px 10px 10px;
        -webkit-backdrop-filter: blur(6px);
        backdrop-filter: blur(6px);
        border-radius: 0 0 8px 8px;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        background: rgba(96, 96, 96, 0.4);

        .tip-padding-container {

            .tips-container {
                padding: 15px 0;
                display: flex;
                flex-direction: column;
                gap: 10px;
                font: 600 0.8em Lato;

                .tip-item-container {
                    display: flex;
                    color: white;

                    .tip-index {}

                    .tip-description {}

                    .coin-container {
                        padding-left: 5px;

                        .coin-icon {
                            height: 10px;
                        }
                    }
                }

                .add-task-label {
                    font-style: italic;
                    color: white;
                }
            }

            .view-tips-menu-button {
                padding: 8px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                cursor: pointer;
                text-transform: uppercase;
                letter-spacing: 0.1em;
                font: 600 0.7em Lato;
                background-color: rgb(255, 202, 51);
            }
        }
    }

    // Utility CSS
    .expanded-tip-header {
        width: 200px;
        justify-content: flex-start;
        border-radius: 8px 8px 0 0;
    }
}

// Utility CSS
.bring-to-front {
    z-index: 40 !important;
}