#overview-profile-info-subcomponent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .profile-photo-container {

        .profile-photo {
            height: 90px;
            width: 90px;
        }
    }

    .username-container {
        display: flex;
        align-items: center;
        gap: 3px;

        .name {
            font-size: 1.4em;
            font-weight: bold;
            color: rgb(51, 51, 51);
        }

        .premium-mini-badge {
            height: 15px;
        }

        .username-skeleton {
            height: 27px;
            width: 100px;
        }
    }

    .badge-container {
        display: flex;
        gap: 5px;

        .premium-badge-container {

            .premium-badge {
                height: 20px;
            }
        }

        .levelling-badge-container {

            .levelling-badge {
                height: 20px;
                display: block;
            }
        }

        .badge-skeleton {
            height: 20px;
            width: 60px;
        }
    }
}