@import "../../../../../global-style.scss";

#interactable-overlay-livestream-chat-input-subcomponent {
    // height: 90px;
    padding: 0 10px;
    display: flex;
    gap: 10px;
    // border-top: 1px solid white;
    overflow-x: auto;

    .gift-and-input-container {
        width: 100%;
        display: flex;

        .more-gifts-container {
            width: 60px;
            display: flex;
            align-items: center;
            cursor: pointer;

            .more-gifts {
                height: 35px;
            }
        }

        .input-container {
            width: calc(100% - 60px);
            min-width: 235px; // Need to change to accomodate desktop view
            // padding-left: 10px;
            display: flex;
            align-items: center;

            // Custom MUI Text Field CSS
            .transparent-field {
                width: 100%;
                padding: 3px 5px 2px 25px;
                border-radius: 25px;
                background-color: rgba(239, 239, 239, 0.3);

                input {
                    color: white;

                    &::placeholder {
                        opacity: 1;
                        color: #919191;
                    }
                }
            }

            // Utility CSS
            .non-transparent-field {
                width: 100%;
                padding: 3px 5px 2px 25px;
                border: 1px solid #ced4da;
                border-radius: 25px;
                background-color: white;

                input {
                    color: #495057;

                    &::placeholder {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .send-icon-container {
        width: 66px;
        // padding-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        .send-icon {
            height: 27px;
        }
    }

    .emoji-list-container {
        margin-left: 10px;
        display: flex;
        align-items: center;
        gap: 10px;

        .game-launcher-icon-container {
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            cursor: pointer;

            .chip-logo {
                height: 100%;
                width: 100%;
                border-radius: 50px;
                object-fit: cover;
            }
        }

        .blow-kiss-emoji-container {
            display: flex;
            cursor: pointer;

            .blow-kiss-emoji {
                height: 40px;
            }
        }

        .heart-eyes-emoji-container {
            display: flex;
            cursor: pointer;

            .heart-eyes-emoji {
                height: 40px;
            }
        }

        .thumbs-up-emoji-container {
            display: flex;
            cursor: pointer;

            .thumbs-up-emoji {
                height: 40px;
            }
        }

        .fire-emoji-container {
            display: flex;
            cursor: pointer;

            .fire-emoji {
                height: 40px;
            }
        }
    }

    .speed-dial-container {
        width: 66px;

        .speed-dial {
            // height: 50px;
            // width: 50px;
            position: absolute;
            bottom: 21px;
            right: 10px;

            & .MuiFab-primary {
                height: 45px;
                width: 45px;
            }

            .speed-dial-action {
                height: 50px;
                width: 50px;
                backdrop-filter: blur(3px);
                background-color: rgb(51, 51, 51, 0.3);
            }
        }
    }
}

/* Hide scrollbar for Chrome, Safari and Opera */
#interactable-overlay-livestream-chat-input-subcomponent::-webkit-scrollbar {
    display: none;
}

// Responsive Design
@media (min-width: 900px) {
    #interactable-overlay-livestream-chat-input-subcomponent {
        padding-top: 10px;
        flex-direction: column;

        .emoji-list-container {
            margin-left: 0;
            justify-content: space-around;
        }
    }
}

@media (min-width: 1305px) {
    #interactable-overlay-livestream-chat-input-subcomponent {
        // If there is a need for different design for tablet
    }
}

@media (max-width: 900px) {
    #interactable-overlay-livestream-chat-input-subcomponent {
        height: 90px;
    }
}