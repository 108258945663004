#account-signup-page {
    height: 100%;

    .max-width-container {
        height: 80%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .create-account-label {
            padding-bottom: 50px;
            text-align: center;
            font-size: 26px;
            font-weight: 700;
        }

        .email-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 30px;
            margin-left: auto;
            margin-right: auto;

            .email-form-field {
                width: 100%;
            }
        }

        .password-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 20px;
            margin-left: auto;
            margin-right: auto;

            .password-form-field {
                width: 100%;
            }
        }

        .signup-button {
            height: 45px;
            margin-top: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 2px solid rgb(113, 13, 13);
            border-radius: 25px;
            text-decoration: none;
            font: 500 1.1em Lato;
            color: rgb(113, 13, 13);
            transition: all 0.5s;

            .button-spinner {
                color: rgb(113, 13, 13);
            }
        }

        .disabled-button {
            border: 2px solid transparent;
            cursor: not-allowed;
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26);
        }

        .terms-and-condition {
            margin-top: 25px;
            line-height: 1.4;
            color: #676767;

            .terms,
            .privacy-policy {
                text-decoration: underline;
                color: rgb(113, 13, 13);
            }
        }
    }
}