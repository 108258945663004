@import "../../../../global-style.scss";

#search-current-location-subcomponent {
    width: 100%;
    border-radius: 50px;
    background-color: #DBDBDB;

    .filter-padding-container {
        padding: 10px 20px 5px 15px;
        display: flex;
        align-items: center;

        .pin-icon {
            padding-bottom: 6px;
            color: $sugarbook-main-color;
        }

        .location-form-container {
            height: 40px;
            // width: 100%;
            padding-left: 5px;
            padding-right: 20px;
            display: flex;
            align-items: center;
            flex-grow: 1;

            .autocomplete-wrapper {
                width: 100%;

                #search-current-location-text-field {
                    color: #9F9F9F;
                }

                .form-field {
                    width: 100%;
                }
            }

            .search-icon {
                color: #676767;
            }
        }

        .change-location-label {
            padding-bottom: 6px;
            text-decoration: underline;
            font: italic 600 0.7em Lato;
            white-space: nowrap;
            color: $sugarbook-main-color;
        }
    }
}

// Google Maps CSS
// To ensure that if is infront of dialog
// Dialog z-index is 1299
.pac-container {
    z-index: 1300;
}

// Utility CSS
.locked {
    cursor: not-allowed;
}