@import "../../../../../global-style.scss";

#edit-profile-location-subcomponent {

    .location-label {
        height: 50px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        font: 500 1.1em Lato;
        background-color: #fafafa;
    }

    .form-field-container {
        height: 50px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;

        .label-container {
            display: flex;
            flex: 1;

            .label {
                color: #919191;
            }

            .info-icon {
                padding-left: 10px;
                font-size: 19px;
            }
        }

        .output {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
        }

        .toggle {
            display: flex;
            justify-content: flex-end;
            flex: 1;
        }

        .form-container {
            display: flex;
            flex: 2;

            .autocomplete-wrapper {
                width: 100%;

                .form-field {
                    width: calc(100% - 45px);
                    padding-top: 3px;
                    padding-left: 30px;
                    padding-right: 15px;
                }
            }
        }
    }

    .reload-location-container {
        height: 35px;
        padding: 5px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        .reload-icon {
            font-size: 20px;
            color: $sugarbook-main-color;
        }

        .reload-location-label {
            font: 600 1em Lato;
            color: $sugarbook-main-color;
        }
    }
}