#leaderboard-section-empty-list-subcomponent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .message-icon-container {
        margin-bottom: 15px;

        .message-icon {
            width: 100px;
        }
    }

    .no-message-text {
        width: 55%;
        text-align: center;
        font: 600 1.3em Lato;
        color: #7c7c7c;
    }
}