#video-call-room-page {
    height: 100%;
    position: relative;

    .embla {
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        position: absolute;
        top: 0;
        z-index: 20;

        .embla__container {
            height: 100%;
            display: flex;

            .embla__slide {
                flex: 0 0 100%;
                min-width: 0;

                .stripped-down-container {
                    height: 100%;
                    display: flex;
                    align-items: flex-end;

                    .gifts-section-container {
                        width: 100%;
                    }
                }

                .interactable-container {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
        }
    }

    .interactable-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-x: hidden;
        position: absolute;
        top: 0;
        z-index: 20;
    }
}