#chat-section-tip-request-element-subcomponent {
    max-width: 80%;
    padding: 0 0 0 15px;

    .padding-container {
        padding: 5px 0 5px 15px;
        display: flex;
        border-radius: 10px;
        background-color: rgba(51, 51, 51, 0.4);

        .profile-picture-container {
            width: 45px;
            display: flex;
            align-items: center;

            .profile-picture {
                height: 30px;
                width: 30px;
            }
        }

        .chat-info-container {
            width: calc(100% - 45px);
            display: flex;
            align-items: center;

            .chat-info {

                .username {
                    font: 500 0.9em/1.4 Lato;
                    color: white;
                }

                .levelling-badge {
                    height: 13px;
                    margin-top: 3px;
                    padding: 0 7px;
                }

                .joined-text {
                    font: 500 0.9em/1.4 Lato;
                    color: white;
                }
            }
        }
    }
}