#user-profile-dialog {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    .max-width-container {
        width: 100%;
        max-width: 1100px;

        .desktop-view {
            padding-bottom: 50px;
            display: flex;
            flex-direction: column;
            gap: 40px;
        }
    }

    .profile-padding-container {
        padding: 20px;
    }
}