#custom-avatar-subcomponent {
    position: relative;

    .custom-avatar {}

    .fallback-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .lock-icon-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .lock-icon {
            height: 20px;
        }
    }
}

// Utility CSS
.blur-avatar {

    img {
        filter: blur(5px);
    }
}