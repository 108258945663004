@import "../../../global-style.scss";

#update-app-component {
    height: 60px;
    background-color: $sugarbook-main-color;

    .padding-container {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .refresh-logo-container {
            width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;

            .refresh-outer-circle {
                height: 40px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50px;
                background-color: white;

                .refresh-logo {
                    color: $sugarbook-main-color;
                }
            }
        }

        .refresh-description-container {
            width: calc(100% - 80px);
            display: flex;
            flex-direction: column;

            .update-available-label {
                color: white;
            }

            .click-to-update-container {
                display: flex;
                align-items: center;

                .click-to-update-label {
                    font: 500 0.8em Lato;
                    color: white;
                }

                .next-icon-container {
                    display: flex;
                    align-items: center;
                    color: white;
                }
            }
        }
    }
}