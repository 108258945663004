#notifications-page {
    height: 100%;

    .notification-list-container {
        height: 100%;
        overflow: auto;

        >div {
            height: 100%;
            display: flex;
            flex-direction: column;

            .infinite-scroller-container {
                // padding: 0 20px 20px 20px;
                padding-bottom: 20px;
            }
        }
    }
}