#chat-section-chat-element-subcomponent {
    padding: 0 0 0 15px;
    display: flex;

    .profile-picture-container {
        width: 45px;

        .profile-picture {
            height: 30px;
            width: 30px;
        }
    }

    .chat-info-container {
        width: 88%;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .user-info-container {
            display: flex;
            align-items: center;
            gap: 7px;

            .livestreaming-achievement-badge-container {
                display: flex;
                align-items: center;
                gap: 3px;

                .livestreaming-achievement-badge {
                    height: 13px;
                }
            }

            .username {
                font: 700 0.9em Lato;
            }

            .levelling-badge-container {
                display: flex;

                .levelling-badge {
                    height: 13px;
                }
            }
        }

        .chat {
            font: 700 0.9em Lato;
        }
    }
}

// Utility CSS
.yellow {
    color: #FFE18C;
}