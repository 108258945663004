#banner-subcomponent {
    width: 100%;
    max-width: 500px;
    position: relative;
    margin: 0 auto;
    cursor: pointer;

    // Carousel View
    .carousel-container {
        width: 100%;

        .swiper-container {
            width: 100%;

            // Pagination CSS
            --swiper-pagination-color: #333333;

            .banner-container {
                width: 100% !important;
                position: relative;

                .banner-image {
                    width: 100%;
                }
            }
        }

        .livestream-timer-container {
            position: absolute;
            bottom: 8px;
            left: 18px;
            font: 600 0.9em Lato;
            color: rgb(255, 240, 0);
        }

        .search-timer-container {
            position: absolute;
            bottom: 35px;
            left: 18px;
            font: 600 0.9em Lato;
            color: rgb(255, 240, 0);
        }

        .user-aio-timer-container {
            position: absolute;
            bottom: 35px;
            left: 18px;
            font: 600 0.9em Lato;
            color: rgb(255, 240, 0);
        }
    }

    // Stack View
    .stack-container {
        width: 100%;

        .leaderboard-banner-container {
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .banner-container {
                width: 100%;
                position: relative;

                .banner-image {
                    width: 100%;
                }

                .livestream-timer-container {
                    position: absolute;
                    bottom: 8px;
                    left: 18px;
                    font: 600 0.9em Lato;
                    color: rgb(255, 240, 0);
                }

                .private-call-timer-container {
                    position: absolute;
                    bottom: 8px;
                    left: 18px;
                    font: 600 0.9em Lato;
                    color: rgb(255, 240, 0);
                }
            }
        }

        .coin-shop-banner-container {

            .coin-shop-banner {
                width: 100%;
            }
        }
    }

    // Scrolling Text View
    .scrolling-text-container {

        .livestream-floating-container {
            // height: 40px; // Add this to make the banner scroll horizontally
            width: 100%;
            margin: auto;
            position: relative;
            display: flex; // Add this to make the banner scroll vertically
            overflow: hidden;
            border-radius: 15px;

            .slider-track {
                height: 40px;
                width: calc(195px * 2);
                min-width: calc(195px * 2);
                display: flex;
                -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
                filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
                -moz-animation: scroll-left 10s linear infinite;
                -webkit-animation: scroll-left 10s linear infinite;
                animation: scroll-left 10s linear infinite;

                .banner-image-container {

                    .banner-image {
                        height: 100%;
                    }
                }

                .banner-description-container {
                    padding: 0 10px 0 3px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;

                    .banner-description-label {
                        white-space: nowrap;
                        text-transform: uppercase;
                        font: 600 0.7em Lato;
                        color: white;
                    }

                    .banner-description-time-left {
                        font: 600 0.7em Lato;
                        color: white;
                    }
                }
            }
        }

        .livestream-floating-timer-container {
            white-space: nowrap;
            font: 600 0.7em Lato;
            color: white;
        }
    }

    // Expandable View
    .expandable-floating-container {
        display: flex;
        justify-content: flex-end;

        .base-container {
            height: 50px;
            width: 50px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 25px;
            transition: width 0.5s;

            .banner-image-container {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .banner-image {
                    // height: 35px;
                    width: 50px;
                }
            }

            .livestream-floating-short-timer-container {
                width: fit-content;
                position: absolute;
                bottom: -20px;
                padding: 3px;
                border-radius: 25px;
                opacity: 0.7;
                // white-space: nowrap;
                text-align: center;
                font: 700 0.6em Lato;
                background-color: black;
                color: white;
            }

            .banner-description-container {
                padding: 0 10px 0 3px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                transition: 1s;

                .banner-description-label {
                    font: 700 0.6em Lato;
                    color: white;
                }

                .livestream-floating-timer-container {
                    font: 600 0.7em Lato;
                    color: white;
                }
            }
        }

        .expand-banner {
            width: 100%;
            display: flex;
            flex-direction: row;
            transition: 1s;

            .banner-image-container {
                width: 50px;
            }

            .banner-description-container {
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
            }

            .expanded {
                animation: expand 1.5s ease-in-out forwards;
            }
        }
    }

    // Utility CSS
    .border-radius {
        border-radius: 10px;

        // Nested Child of Carousel
        .slider-wrapper {
            border-radius: 10px;
        }
    }

    .default-floating-banner-gradient {
        background: linear-gradient(90.03deg, rgba(72, 27, 11, 0.5) 32.49%, rgba(255, 51, 51, 0.5) 105.27%);
    }

    .brown_to_red {
        background: linear-gradient(90.03deg, rgba(72, 27, 11, 0.8) 32.49%, rgba(255, 51, 51, 0.8) 105.27%);
    }

    .purple_gradient {
        background: linear-gradient(90.03deg, rgba(61, 4, 4, 0.8) 32.49%, rgba(226, 51, 255, 0.8) 105.27%);
    }

    .blue_gradient {
        background: linear-gradient(90.03deg, rgba(51, 133, 255, 0.8) 32.49%, rgba(19, 9, 79, 0.8) 105.27%);
    }

    .purple_to_red {
        background: linear-gradient(90.03deg, rgba(71, 11, 72, 0.8) 32.49%, rgba(255, 51, 51, 0.8) 105.27%);
    }

    .dark_red {
        background: linear-gradient(90.03deg, rgba(181, 0, 0, 0.8) 32.49%, rgba(0, 0, 0, 0.8) 105.27%);
    }

    .turquoise_to_pink {
        background: linear-gradient(90.03deg, rgba(60, 170, 171, 0.8) 32.49%, rgba(202, 44, 92, 0.8) 105.27%);
    }

    .light_blue_to_yellow {
        background: linear-gradient(90.03deg, rgba(9, 185, 254, 0.8) 32.49%, rgba(248, 252, 201, 0.8) 105.27%);
    }

    .orange_gradient {
        background: linear-gradient(90.03deg, rgba(255, 164, 98, 0.8) 32.49%, rgba(236, 2, 0, 0.8) 105.27%);
    }

    .light_pink_to_yellow {
        background: linear-gradient(90.03deg, rgba(233, 142, 143, 0.8) 32.49%, rgba(253, 226, 137, 0.8) 105.27%);
    }

    .yellow_gradient {
        background: linear-gradient(90.03deg, rgba(255, 228, 133, 0.8) 32.49%, rgba(255, 160, 0, 0.8) 105.27%);
    }
}

// Responsive Design
@media (max-width: 500px) {
    #banner-subcomponent {
        margin: 0;
    }
}

//
@keyframes expand {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}