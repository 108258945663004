#product-purchase-dialog-popular-tab-subcomponent {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: auto;
}

// Utility CSS
.popular-gift-viewport-height {
    height: 42vh;
}

.dynamic-height {
    height: 100%;
}