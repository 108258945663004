@import "../../../global-style.scss";

#update-password-page {
    height: 100%;

    .max-width-container {
        height: calc(100% - 10px);
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        background-color: white;

        .padding-container {
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .old-password-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 20px;
            margin-left: auto;
            margin-right: auto;

            .password-form-field {
                width: 100%;
            }
        }

        .new-password-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 20px;
            margin-left: auto;
            margin-right: auto;

            .password-form-field {
                width: 100%;
            }
        }

        .confirm-password-form-field-container {
            height: 50px;
            width: 100%;
            padding-bottom: 20px;
            margin-left: auto;
            margin-right: auto;

            .password-form-field {
                width: 100%;
            }
        }

        .update-password-button {
            height: 45px;
            margin-top: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            text-transform: none;
            text-decoration: none;
            font-size: 1.1em;
            font-weight: 500;
            background-color: $sugarbook-main-color;
            color: white;
            transition: all 0.5s;

            .button-spinner {
                color: white;
            }
        }

        .disabled-button {
            border: 2px solid transparent;
            cursor: not-allowed;
            background-color: rgba(0, 0, 0, 0.12);
            color: rgba(0, 0, 0, 0.26);
        }
    }
}

// Responsive Design
@media (min-width: 720px) {
    #update-password-page {

        .max-width-container {
            border-left: 1px solid #e5e7eb;
            border-right: 1px solid #e5e7eb;
        }
    }
}