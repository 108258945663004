#spinner-subcomponent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.default-spinner {

    .MuiCircularProgress-root {
        color: #710d0d;
    }
}

.spinner-alt {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    transform: scale(1.2);

    .spinner-alt-blade {
        position: absolute;
        width: 4px;
        height: 4px;
        background: #710D0D;
        border-radius: 30px;
        animation: spinner-alt 1.2s linear infinite;
    }

    .spinner-alt-blade:nth-child(1) {
        animation-delay: 0s;
        top: 37px;
        left: 66px;
    }

    .spinner-alt-blade:nth-child(2) {
        animation-delay: -0.1s;
        top: 22px;
        left: 62px;
    }

    .spinner-alt-blade:nth-child(3) {
        animation-delay: -0.2s;
        top: 11px;
        left: 52px;
    }

    .spinner-alt-blade:nth-child(4) {
        animation-delay: -0.3s;
        top: 7px;
        left: 37px;
    }

    .spinner-alt-blade:nth-child(5) {
        animation-delay: -0.4s;
        top: 11px;
        left: 22px;
    }

    .spinner-alt-blade:nth-child(6) {
        animation-delay: -0.5s;
        top: 22px;
        left: 11px;
    }

    .spinner-alt-blade:nth-child(7) {
        animation-delay: -0.6s;
        top: 37px;
        left: 7px;
    }

    .spinner-alt-blade:nth-child(8) {
        animation-delay: -0.7s;
        top: 52px;
        left: 11px;
    }

    .spinner-alt-blade:nth-child(9) {
        animation-delay: -0.8s;
        top: 62px;
        left: 22px;
    }

    .spinner-alt-blade:nth-child(10) {
        animation-delay: -0.9s;
        top: 66px;
        left: 37px;
    }

    .spinner-alt-blade:nth-child(11) {
        animation-delay: -1s;
        top: 62px;
        left: 52px;
    }

    .spinner-alt-blade:nth-child(12) {
        animation-delay: -1.1s;
        top: 52px;
        left: 62px;
    }
}

.ios-spinner {
    position: relative;
    width: 54px;
    height: 54px;
    display: inline-block;
    padding: 10px;
    border-radius: 10px;

    .spinner-blade {
        width: 6%;
        height: 16%;
        background: #FFF;
        position: absolute;
        left: 49%;
        top: 43%;
        opacity: 0;
        -webkit-border-radius: 50px;
        -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        -webkit-animation: spinner-fade 1s linear infinite;
    }

    .spinner-blade:nth-child(1) {
        -webkit-transform: rotate(0deg) translate(0, -130%);
        -webkit-animation-delay: 0s;
    }

    .spinner-blade:nth-child(2) {
        -webkit-transform: rotate(30deg) translate(0, -130%);
        -webkit-animation-delay: -0.9167s;
    }

    .spinner-blade:nth-child(3) {
        -webkit-transform: rotate(60deg) translate(0, -130%);
        -webkit-animation-delay: -0.833s;
    }

    .spinner-blade:nth-child(4) {
        -webkit-transform: rotate(90deg) translate(0, -130%);
        -webkit-animation-delay: -0.7497s;
    }

    .spinner-blade:nth-child(5) {
        -webkit-transform: rotate(120deg) translate(0, -130%);
        -webkit-animation-delay: -0.667s;
    }

    .spinner-blade:nth-child(6) {
        -webkit-transform: rotate(150deg) translate(0, -130%);
        -webkit-animation-delay: -0.5837s;
    }

    .spinner-blade:nth-child(7) {
        -webkit-transform: rotate(180deg) translate(0, -130%);
        -webkit-animation-delay: -0.5s;
    }

    .spinner-blade:nth-child(8) {
        -webkit-transform: rotate(210deg) translate(0, -130%);
        -webkit-animation-delay: -0.4167s;
    }

    .spinner-blade:nth-child(9) {
        -webkit-transform: rotate(240deg) translate(0, -130%);
        -webkit-animation-delay: -0.333s;
    }

    .spinner-blade:nth-child(10) {
        -webkit-transform: rotate(270deg) translate(0, -130%);
        -webkit-animation-delay: -0.2497s;
    }

    .spinner-blade:nth-child(11) {
        -webkit-transform: rotate(300deg) translate(0, -130%);
        -webkit-animation-delay: -0.167s;
    }

    .spinner-blade:nth-child(12) {
        -webkit-transform: rotate(330deg) translate(0, -130%);
        -webkit-animation-delay: -0.0833s;
    }
}

// Utility CSS
.apply-padding {
    padding-top: 10%;
}

.white-spinner {

    .MuiCircularProgress-root {
        color: white;
    }
}