#navbar-navbar-desktop-view-subcomponent {
    height: 49px;
    padding: 10px 0;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid lightgrey;

    .space-around {
        width: 100%;
        max-width: 1020px;
        padding: 0 12px;

        .login-view,
        .not-login-view {
            height: 100%;
            display: flex;
            justify-content: space-between;

            .logo-navlink-container {
                display: flex;
                gap: 10px;
            }

            .burger-menu-button {
                display: none;
            }

            .sugarbook-logo-container {
                height: 100%;
                width: fit-content;
                display: flex;
                align-items: center;
                cursor: pointer;

                .sugarbook-logo {
                    height: 40px;
                    padding: 7px;
                }
            }

            .info-dropdown-button {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 20px;

                .info-item {
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: 16px;
                    color: #464646;
                }
            }

            .navigation-left-container {
                // width: 400px;
                display: flex;
                justify-content: space-evenly;

                .link-container {
                    min-width: 72px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                    text-decoration: none;
                    cursor: pointer;
                    // color: #9d9d9d;

                    .search-badge {

                        .MuiBadge-dot {
                            top: 7px;
                        }
                    }

                    .link-logo-container {
                        height: 30px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .search-icon {
                            height: 20px;
                        }

                        .message-icon {
                            height: 19px;
                        }

                        .favorite-icon {
                            height: 20px;
                        }

                        .video-icon {
                            height: 19px;
                        }

                        .live-icon {
                            height: 24px;
                        }
                    }

                    .link-label {
                        font: 500 0.8em Lato;
                        color: black;
                    }
                }
            }

            .navigation-right-container {
                display: flex;
                justify-content: center;
                align-items: center;

                .login-container {
                    display: flex;
                    align-items: center;
                    gap: 15px;

                    .notification-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .notification-icon {
                            width: 30px;
                        }

                        .notification-label {
                            font: 500 0.8em Lato;
                        }
                    }

                    .sb-coin-icon-container {
                        height: 50px;
                        // width: 50px;
                        padding: 0 10px;
                        display: flex;
                        justify-content: center;
                        cursor: pointer;

                        .sb-coin-icon {
                            width: 30px;
                        }
                    }

                    .diamond-icon-container {
                        height: 50px;
                        // width: 50px;
                        padding: 0 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;

                        .diamond-icon {
                            width: 30px;
                        }
                    }

                    .leaderboard-new-icon-container {
                        height: 50px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        .leaderboard-new-icon {
                            height: 35px;
                        }
                    }

                    .upgrade-button {
                        padding: 8px 15px;
                        border: 2px solid #710D0D;
                        border-radius: 25px;
                        cursor: pointer;
                        text-decoration: none;
                        font: 600 1em Lato;
                        color: #710D0D;
                    }

                    .profile-container {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .profile-logo-container {
                            display: flex;
                            cursor: pointer;

                            .profile-logo {
                                border-radius: 25px;
                            }
                        }

                        .dropdown-logo-container {
                            display: flex;
                            cursor: pointer;
                        }
                    }
                }

                .not-login-container {
                    display: flex;

                    .login-button-container {
                        padding: 7px 18px;
                        cursor: pointer;
                        font: 400 1em Lato;
                        background-color: white;

                        .login-button {
                            text-decoration: none;
                            color: #333;
                        }
                    }

                    .join-button-container {
                        padding: 7px 18px;
                        cursor: pointer;
                        border-radius: 25px;
                        font: 700 1em Lato;

                        background-color: #710d0d;

                        .join-button {
                            text-decoration: none;
                            color: white;
                        }
                    }
                }
            }

            // Utility CSS
            .default-gap {
                gap: 10px;
            }

            .reduce-gap {
                gap: 6px
            }
        }
    }


    // Utility CSS
    .dropdown-logo {
        display: flex;
    }

    .no-decoration {
        text-decoration: none;
        color: #464646;
    }
}

// Utility CSS
.navbar-no-decoration {
    text-decoration: none;
    color: inherit;
}

.menu-item-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .menu-item-label {}

    .menu-item-suffix-container {
        display: flex;
        align-items: center;
        gap: 5px;

        .coin-icon {
            width: 25px;
        }

        .diamond-icon {
            height: 18px;
        }

        .suffix-label {
            font: 600 1.2em Lato;
            color: rgb(113, 13, 13);
        }
    }
}

.navbar-verified-profile-badge {
    height: 20px;
    padding-left: 5px;
}

// Responsive Design
@media (min-width: 960px) {
    #navbar-navbar-desktop-view-subcomponent {

        .sugarbook-logo {
            padding: 7px 7px 0 7px !important;
        }
    }
}

@media (max-width: 720px) {
    #navbar-navbar-desktop-view-subcomponent {
        display: none;
    }
}