#profile-view-looking-for-info-subcomponent {

    .padding-container {
        padding: 20px;

        .label {
            font: 600 1.1em Lato;
        }

        .text {
            color: #676767;
        }
    }
}