#viewed-me-tab-empty-list-subcomponent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .message-icon-container {
        margin-bottom: 15px;

        .message-icon {
            width: 100px;
        }
    }

    .no-message-text {
        width: 60%;
        text-align: center;
        line-height: 1.3;
        font-size: 1em;
        color: #7c7c7c;
    }

    .browse-member-button {
        height: 45px;
        padding-left: 40px;
        padding-right: 40px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        border-radius: 25px;
        text-decoration: none;
        font-size: 1.1em;
        font-weight: 500;
        background-color: #710d0d;
        color: white;
    }
}