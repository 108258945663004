#upgrade-upgrade-perks-subcomponent {
    height: 280px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .carousel-container {
        height: 100%;

        .swiper-container {
            height: 100%;

            // Pagination CSS
            --swiper-pagination-color: #333333;

            .daddy-perks,
            .baby-perks {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .perk-image {
                    height: 70px;
                }

                .text-container {
                    padding-top: 20px;

                    .title {
                        text-align: center;
                        font: 600 1.4em Lato;
                    }

                    .subtitle {
                        padding-top: 10px;
                        text-align: center;
                        font: 400 1.1em Lato;
                        color: #7C7C7C;
                    }
                }
            }
        }
    }
}

.christmas-mobile-background {
    background: url("../../../../assets/background/shared/christmas-mobile-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.valentine-mobile-background {
    background: url("../../../../assets/background/shared/valentine-mobile-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}