#settings-logging-settings-subcomponent {

    .padding-container {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .logging-mode-label {
            font: 600 1.1em Lato;
        }

        .logging-mode-switch-container {
            display: flex;
            justify-content: center;
        }
    }
}