#casino-tab-game-list-tab-subcomponent {
    height: 100%;

    .embla {
        height: 100%;
        width: 100%;
        overflow-x: hidden;

        .embla__container {
            height: 100%;
            display: flex;

            .embla__slide {
                flex: 0 0 100%;
                min-width: 0;
            }
        }
    }
}