@import "../../../../global-style.scss";

#gifts-tab-gifts-subcomponent {
    height: fit-content;
    width: calc(110px - 40px - 4px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid transparent;
    cursor: pointer;

    .gifts-image-container {
        padding-bottom: 10px;
        position: relative;

        .gifts-image {
            height: 50px;
            max-width: 100%;
        }

        .grey-gifts-image {
            height: 50px;
            filter: grayscale(1);
            -webkit-filter: grayscale(1);
        }

        .gift-event-container {
            width: 100%;
            position: absolute;
            bottom: 6px;

            .event-label {
                width: fit-content;
                margin-left: auto;
                margin-right: auto;
                padding: 2px 5px;
                border-radius: 25px;
                text-align: center;
                font: 600 0.5em Lato;
                background-color: #ff0000;
                color: white;
            }
        }
    }

    .amount-container {
        display: flex;

        .coin-icon-container {
            display: flex;
            align-items: center;

            .coin-icon {
                height: 15px;
            }
        }

        .amount {
            padding-left: 2px;
            font: 600 1em Lato;
            color: $sugarbook-main-color;
        }
    }
}

// Utility CSS
.selected-gift {
    border: 2px solid $sugarbook-main-color !important;
    border-radius: 25px;
}