#blocked-members-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        max-width: 1000px;
        margin: 0 auto;
        background-color: white;

        .pull-to-refresh-container {

            .blocked-users-list-container {
                height: 100%;
                overflow: auto;

                .infinite-scroller-container {}
            }
        }

        // Utility CSS
        .full-height {
            height: 100%;
        }

        .desktop-height {
            height: calc(100% - 50px - 1px);
        }
    }
}

// Responsive Design
@media (min-width: 1000px) {
    #blocked-members-page {

        .max-width-container {
            border-left: 1px solid #e5e7eb;
            border-right: 1px solid #e5e7eb;
        }
    }
}