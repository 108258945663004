@import "../../../../global-style.scss";

#messages-tab-messages-list-subcomponent {
    cursor: pointer;

    .padding-container {
        padding-left: 15px;
        padding-right: 15px;

        .message-container {
            height: 90px;
            display: flex;

            .user-image-container {
                width: 70px;
                display: flex;
                align-items: center;

                .user-image {
                    height: 70px;
                    width: 70px;
                }
            }

            .user-description-container {
                width: calc(100% - 70px - 100px);
                padding: 15px 0 15px 15px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .user-top-container {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .premium-mini-badge-container {
                        display: flex;
                        align-items: center;

                        .premium-mini-badge {
                            height: 13px;
                        }
                    }

                    .livestreaming-achievement-badge-container {
                        display: flex;
                        align-items: center;
                        gap: 3px;

                        .livestreaming-achievement-badge {
                            height: 13px;
                        }
                    }

                    .user-name {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font: 500 1.1em Lato;
                    }

                    .user-badge-container {
                        display: flex;
                        align-items: center;
                        gap: 5px;

                        .verified-profile-badge-container {
                            display: flex;
                            align-items: center;

                            .verified-profile-badge {
                                width: 16px;
                            }
                        }

                        .verified-caller-badge-container {
                            display: flex;

                            .verified-caller-badge {
                                height: 16px;
                            }
                        }

                        .admin-badge-container {
                            height: 13px;
                            width: 50px;
                            margin-left: 5px;
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                            border-radius: 25px;
                            background-color: rgba(205, 79, 79, .78);
                            color: white;

                            .person-icon {
                                font-size: 10px;
                            }

                            .admin-label {
                                display: flex;
                                align-items: center;
                                font: 500 0.7em Lato;
                            }
                        }

                        .level-badge-container {
                            display: flex;
                            align-items: center;

                            .level-badge {
                                height: 13px;
                            }
                        }
                    }
                }

                .user-bottom-container {

                    .user-last-message {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: rgba(0, 0, 0, .6);
                    }
                }
            }

            .user-last-updated-container {
                width: 100px;
                padding-top: 15px;
                padding-bottom: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: flex-end;

                .last-updated {
                    white-space: nowrap;
                    text-align: right;
                    font: 500 0.8em Lato;
                    color: rgba(0, 0, 0, .6);
                }

                .blue-tick-icon {
                    height: 10px;
                }

                .grey-tick-icon {
                    height: 10px;
                }

                .read {
                    height: 15px;
                    width: 15px;
                    border-radius: 50px;
                    background-color: $sugarbook-main-color;
                }
            }
        }
    }

    .divider {
        width: calc(100% - 100px);
        margin-left: auto;
    }
}

// Utility CSS
.selected-conversation {
    background-color: rgba(0, 0, 0, 0.07);
}