#user-profile-tab-about-me-tab-subcomponent {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .about-me-container {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .about-me-label {
            font: 600 1.4em Lato;
        }

        .about-me {
            font: 400 1em/1.5 Lato;
            color: #676767;
        }
    }

    .looking-for-container {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .looking-for-label {
            font: 600 1.4em Lato;
        }

        .looking-for {
            font: 400 1em/1.5 Lato;
            color: #676767;
        }
    }
}