#private-standby-standby-info-overlay-subcomponent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .standby-info-container {
        width: 300px;
        border-radius: 25px;
        z-index: 30;
        backdrop-filter: blur(6px);
        -webkit-backdrop-filter: blur(6px);
        background-color: rgba(51, 51, 51, 0.8);

        .header-container {
            height: 30px;
            display: flex;
            align-items: center;
            border-radius: 25px 25px 0 0;
            cursor: pointer;
            background-color: #FFDF2B;

            .minimize-icon-container {
                display: flex;
                justify-content: center;
                flex: 1;

                .minimize-icon {
                    font-size: 15px;
                }
            }

            .subheader {
                flex: 4;
                text-align: center;
                font: 600 0.8em Lato;
            }

            .right-container {
                flex: 1;
            }
        }

        .content-container {
            padding-top: 45px;
            padding-bottom: 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 25px;

            .header-section {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .header {
                    text-align: center;
                    font: 600 1.3em Lato;
                    color: white;
                }

                .header-description {
                    text-align: center;
                    font: italic 500 0.8em Lato;
                    color: white;
                }
            }

            .timer {
                text-align: center;
                font: 700 1.8em Lato;
                color: #06E043;
            }

            .diamond-section {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .diamonds-earned-text {
                    text-align: center;
                    font: 600 0.9em Lato;
                    color: white;
                }

                .diamonds-earned-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .diamond-icon {
                        height: 25px;
                    }

                    .diamonds-earned {
                        padding-left: 5px;
                        font: 700 1.2em Lato;
                        color: #66C3FF;
                    }
                }
            }

            .claim-diamonds-button {
                padding: 12px 25px;
                border-radius: 25px;
                cursor: pointer;
                font: 600 0.9em Lato;
                background: linear-gradient(90.38deg, #50B343 5.04%, #148205 119.21%);
                color: white;
            }

            .no-diamonds {
                padding: 12px 25px;
                border-radius: 25px;
                cursor: not-allowed;
                font: 600 0.9em Lato;
                background: #f4f5f7;
                color: lightgrey;
            }
        }

        .tips-container {
            height: 53px;
            padding: 15px 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-top: 2px solid rgba(255, 255, 255, 0.5);

            .tips-title {
                opacity: 0;
                font: 600 1em Lato;
                color: white;
                transition: opacity 1s ease-in-out;
            }

            .tips-title.show {
                opacity: 1;
            }

            .tips-description {
                opacity: 0;
                text-align: center;
                font: 500 0.7em Lato;
                color: white;
                transition: opacity 1s ease-in-out;
            }

            .tips-description.show {
                opacity: 1;
            }
        }
    }
}