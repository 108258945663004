#chat-conversation-conversation-list-subcomponent {
    flex: 1;
    overflow: auto;
    background-image: url("../../../../assets/background/inbox/chat-background.png");
    background-size: contain;

    .padding-container {
        height: 100%;
        // height: calc(100% - 15px);
        // padding: 15px 20px 0px 20px;

        .caution-container {
            max-width: 430px;
            // margin: 0 auto 20px auto;
            margin: 0 auto;
            padding: 10px;
            display: flex;
            justify-content: space-between;
            border-radius: 10px;
            background-color: #ffefb1;

            .caution-logo-container {
                width: 20%;
                display: flex;
                justify-content: center;
                align-items: center;

                .caution-logo {}
            }

            .caution-description {
                width: 78%;
                text-transform: uppercase;
                font: 500 0.8em Lato;
                color: #333;

                .sugarbaby-note {
                    padding-top: 10px;
                }
            }
        }

        .conversation-list-container {
            height: 100%;
            overflow: auto;
            overflow-anchor: auto;

            .single-conversation-container {
                margin: 8px 0;

                .date-container {
                    height: 25px;
                    width: fit-content;
                    margin: 20px auto;
                    padding: 0 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    background-color: #e0e3ea;
                }

                .message-container {
                    width: fit-content;
                    max-width: 75%;
                    padding: 12px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    border-radius: 10px;
                    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);

                    .message-content {
                        width: 100%;
                        white-space: pre-wrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font: 500 1em/1.4 Lato;
                    }

                    .bottom-container {
                        padding-top: 5px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 5px;

                        .message-timestamp {
                            font: 500 0.8em Lato;
                            color: #8c8c8c;
                        }

                        .tick-container {
                            display: flex;
                            align-items: center;

                            .tick {
                                width: 17px;
                            }
                        }

                        .premium-read-label {
                            padding-bottom: 1px;
                            cursor: pointer;
                            font: bold 0.7em Lato;
                            color: #757575;
                        }
                    }
                }
            }

            .call-canceled-container {
                height: 25px;
                width: fit-content;
                margin: 20px auto;
                padding: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 10px;
                box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
                background-color: #efefef;
                color: #333;

                .call-canceled {
                    font: 400 0.8em Lato;
                }

                .timestamp {
                    font: 400 0.8em Lato;
                }
            }

            .infinite-scroller-container {

                .infinite-scroller-body-container {
                    // height: calc(100% - 40px);
                    padding: 15px 20px 0px 20px;
                    display: flex;
                    flex-direction: column-reverse;
                }
            }
        }

        // Utility CSS
        .own-message {
            background-color: #f6e4e5;
        }

        .other-message {
            background-color: white;
        }

        .push-left {
            margin-right: auto;
        }

        .push-right {
            margin-left: auto;
        }
    }
}

// Utility CSS
.conversation-rounded-corner {
    border-radius: 25px;
}

.desktop-height {
    height: calc(100% - 1px - 150px - 47px);
}

.mobile-height {
    height: calc(100% - 1px - 150px);
}