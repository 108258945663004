@import "../../global-style.scss";

#join-page {
    height: 100%;

    .max-width-container {
        height: 80%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 40px;

        .header {
            font: 600 1.2em Lato;
        }

        .i-am-container,
        .looking-for-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .looking-for-label-container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .role-info {
                    display: flex;
                    cursor: pointer;

                    .help-icon {}
                }
            }

            .selection-container {
                width: 100%;
                display: flex;
                gap: 10px;
            }

            .body-label {
                height: 45px;
                width: 50%;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid $mui-disabled-button-color;
                border-radius: 25px;
                cursor: pointer;
                color: $sugarbook-default-text-color;
            }
        }

        .continue-button {
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 25px;
            text-decoration: none;
            text-transform: none;
            cursor: pointer;
            font: 500 1.1em Lato;
            background-color: $sugarbook-main-color;
            color: white;
        }

        .disabled-button {
            cursor: not-allowed;
            background-color: $mui-disabled-button-background-color;
            color: $mui-disabled-button-color;
        }
    }

    // Utility CSS
    .selected {
        border: 3px solid $join-page-border-color !important;
        font: 600 1em Lato;
        background-color: #710d0d15;
        color: $join-page-text-color !important;
    }
}

// Responsive Design
@media (max-width: 768px) {
    #join-page {

        .max-width-container {
            flex-direction: column;

            .i-am-container,
            .looking-for-container {
                flex-direction: column;
                // gap: 0;

                .selection-container {
                    flex-direction: column;

                    .body-label {
                        width: calc(100% - 6px);
                    }
                }
            }
        }
    }
}