#profile-edit-photos-subcomponent {

    .padding-container {
        padding: 10px;

        .public-photos-container {
            padding: 10px;

            .public-photos-label {
                padding-bottom: 10px;
                font: 600 1em Lato;
            }

            .photos-container {
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;
                gap: 10px;

                .photo-container {
                    height: 100px;
                    width: 100px;
                    position: relative;

                    .public-photo {
                        height: 100%;
                        width: 100%;
                        border-radius: 5px;
                        object-fit: cover;
                    }

                    .in-review-overlay {
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5px;
                        text-transform: uppercase;
                        font: 600 0.7em Lato;
                        background-color: rgba(0, 0, 0, 0.53);
                        color: white;
                    }
                }

                .add-photo-container {
                    height: 100px;
                    width: 100px;

                    .add-photo {
                        height: 100%;
                        width: 100%;

                    }
                }
            }
        }

        .private-photos-container {
            padding: 10px;

            .private-photos-label {
                padding-bottom: 10px;
                font: 600 1em Lato;
            }

            .photos-container {
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;
                gap: 10px;

                .photo-container {
                    height: 100px;
                    width: 100px;
                    position: relative;

                    .private-photo {
                        height: 100%;
                        width: 100%;
                        border-radius: 5px;
                        object-fit: cover;
                    }

                    .in-review-overlay {
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5px;
                        text-transform: uppercase;
                        font: 600 0.7em Lato;
                        background-color: rgba(0, 0, 0, 0.53);
                        color: white;
                    }
                }

                .add-photo-container {
                    height: 100px;
                    width: 100px;

                    .add-photo {
                        height: 100%;
                        width: 100%;

                    }
                }
            }
        }

        .upload-photo-container {
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            border-radius: 5px;
            background-color: rgb(250, 250, 250);

            .upload-icon-container {
                display: flex;
                align-items: center;

                .upload-icon {}
            }

            .upload-photo-label {
                font: 600 1em Lato;
                color: grey;
            }
        }
    }
}