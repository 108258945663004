#livestreaming-tab-events-tab-subcomponent {
    height: 100%;

    .tab-container {

        .pk-icon-container {

            .pk-icon {
                height: 20px;
            }
        }
    }

    .leaderboard-events-tab-embla {
        height: 100%;
        width: 100%;
        overflow-x: hidden;

        .embla__container {
            height: 100%;
            display: flex;

            .embla__slide {
                flex: 0 0 100%;
                min-width: 0;
            }
        }
    }
}