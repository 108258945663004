@import "../../../../../global-style.scss";

#edit-profile-basic-information-subcomponent {

    .basic-information-label {
        height: 50px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        font: 500 1.1em Lato;
        background-color: #fafafa;
    }

    .form-field-container {
        height: 50px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;

        .label-container {
            display: flex;
            flex: 1;

            .label {
                color: #919191;
            }

            .info-icon {
                padding-left: 10px;
                font-size: 19px;
            }

            // Utility CSS
            .incomplete-field {
                color: red;
            }
        }

        .output {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
        }

        .toggle {
            display: flex;
            justify-content: flex-end;
            flex: 1;
        }

        .form-container {
            display: flex;
            align-items: center;
            flex: 2;

            .form-field {
                width: 100%;
                padding-top: 3px;
                padding-left: 30px;
                padding-right: 15px;
            }

            .rejected-reason-icon {
                font-size: 20px;
                color: #CCCCCC;
            }

            .rejected-value {

                .MuiInput-root {
                    color: red;
                }
            }

            .email-label {
                padding-left: 30px;
            }
        }

        .date-picker-container {
            width: 100%;
            padding-left: 30px;

            .react-date-picker {
                width: 100%;

                .react-date-picker__wrapper {
                    border: none;
                }
            }

            .react-calendar {
                border: none;
                border-radius: 5px;
                box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);

                button {
                    height: 50px;
                    width: 50px;
                    border-radius: 50px;
                }

                .react-calendar__tile--active {
                    background-color: $sugarbook-main-color;
                }
            }
        }
    }
}