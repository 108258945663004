#edit-profile-description-subcomponent {
    padding-bottom: 40px;

    .description-label {
        height: 50px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        font: 500 1.1em Lato;
        background-color: #fafafa;
    }

    .form-field-container {
        padding-left: 20px;
        padding-right: 20px;
        border-bottom: 1px solid #e0e0e0;

        .label-container {
            padding-top: 15px;
            display: flex;
            flex: 1;
            gap: 10px;

            .label {
                color: #919191;
            }

            .in-review-container {
                padding: 4px 7px;
                border: 1px solid #CCCCCC;
                border-radius: 5px;
                text-transform: uppercase;
                font: 600 0.7em Lato;
                color: #999999;
            }

            .info-icon {
                padding-left: 10px;
                font-size: 19px;
            }

            .rejected-reason-icon {
                font-size: 20px;
                color: #CCCCCC;
            }

            // Utility CSS
            .incomplete-field {
                color: red;
            }
        }

        .form-container {
            padding-top: 25px;

            .form-field {
                width: calc(100% - 60px);
                padding-top: 3px;
                padding-left: 15px;
                padding-right: 15px;
            }

            .character-limit-label {
                margin-bottom: 10px;
                text-align: right;
            }

            .rejected-value {

                .MuiInput-root {
                    color: red;
                }
            }
        }
    }
}