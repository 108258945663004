@import "../../../../../global-style.scss";

#tor-tag-default-tag-subcomponent {
    position: relative;
    padding: 5px 13px;
    border: 1px solid rgba(113, 13, 13, .2);
    border-radius: 50px;
    cursor: help;
    font: 500 0.9em Lato;
    color: $sugarbook-main-color;
    transition: all 0.5s;
}

// Utility CSS
.default-matched {
    background-color: #f4ecec;
}

.default-unmatched {
    background-color: white;
}