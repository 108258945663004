#chat-section-joining-element-subcomponent {
    padding: 0 0 0 15px;
    display: flex;

    .profile-picture-container {
        width: 45px;
        display: flex;
        align-items: center;

        .profile-picture {
            height: 30px;
            width: 30px;
        }
    }

    .chat-info-container {
        width: calc(100% - 45px);
        display: flex;
        align-items: center;

        .chat-info {
            width: 100%;
            // display: flex;
            align-items: center;
            gap: 7px;

            .livestreaming-achievement-badge-container {

                .livestreaming-achievement-badge {
                    height: 13px;
                    margin-right: 3px;
                }
            }

            .username {
                max-width: calc(100% - 40px - 153px - 14px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font: 700 0.9em/1.4 Lato;
            }

            .levelling-badge-container {
                margin: 0 3px;

                .levelling-badge {
                    height: 13px;
                    position: relative;
                    top: 2px;
                }
            }

            .joined-text {
                font: 700 0.9em/1.4 Lato;
            }
        }
    }
}

// Utility CSS
.yellow {
    color: #FFE18C;
}

.red {
    color: rgb(204 24 31/1);
}