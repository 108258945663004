@import "../../../../../../global-style.scss";

.notification-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .notification-badge {

        .MuiBadge-dot {
            height: 6px;
            min-width: 6px;
            top: 7px;
            right: 8px;
        }
    }

    .notification-icon {
        width: 30px;
    }

    .notification-label {
        font: 500 0.6em Lato;
    }
}

.notification-max-width-container {
    width: 400px;
    max-width: 400px;

    .notification-header-container {
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .notifications-label {
            font: 600 1.5em Lato;
        }

        .see-all-button {
            cursor: pointer;
            text-decoration: underline;
            font: 600 1em Lato;
            color: $sugarbook-main-text-color;
        }
    }
}