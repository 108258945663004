@import "../../../../global-style.scss";

#search-category-tab-subcomponent {
    height: 55px;
    // padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    .category-container {
        height: 55px;
        display: flex;
        align-items: center;
        gap: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        .tab-container {
            position: relative;
            padding: 5px 13px;
            border-radius: 30px;
            cursor: pointer;
            font: 500 1em Lato;
            color: #7c7c7c;

            .new-badge-container {
                position: absolute;
                left: 100px;
                bottom: 15px;

                .new-badge {
                    width: 30px;
                }
            }
        }

        // Utility CSS
        .selected-tab {
            font-weight: 600;
            background-color: rgb(113, 13, 13);
            color: white
        }
    }

    .category-form-field-container {
        // display: flex;
        align-items: center;
        gap: 10px;

        .add-underline {
            border-bottom: 1px solid $mui-tabs-border-bottom-color;
        }

        .sort-by-label {
            text-transform: uppercase;
        }

        .category-form-field {

            .MuiInputBase-root {
                border-radius: 30px;

                .MuiSelect-select {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }
        }
    }

    .view-select-container {
        display: flex;
        gap: 10px;

        .list-view-button {
            cursor: pointer;
            transition: all 0.3s ease-in-out;
        }

        .grid-view-button {
            cursor: pointer;
            transition: all 0.3s ease-in-out;
        }

        .deselected-view {
            color: lightgrey;
        }
    }
}

#search-category-tab-skeleton-subcomponent {
    height: 55px;
    width: 100%;
    // padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    .category-container {
        height: 55px;
        display: flex;
        align-items: center;
        gap: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */

        // Utility CSS
        .skeleton-tab {
            min-width: 100px;
            font-size: 2em;
        }
    }

    .category-form-field-container {
        display: flex;
        align-items: center;
        gap: 10px;

        // Utility CSS
        .skeleton-tab {
            height: 40px;
            width: 100%;
        }
    }
}

// Utility CSS
.category-tab-full-width {
    width: 100%;
}

.apply-padding {
    padding: 0 15px;
}

// Responsive Design
@media (min-width: 720px) {
    #search-category-tab-subcomponent {}
}

/* Hide scrollbar for Chrome, Safari and Opera */
#search-category-tab-subcomponent::-webkit-scrollbar {
    display: none;
}