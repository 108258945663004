@import "../../../../../global-style.scss";

#edit-profile-terms-of-relationship-subcomponent {

    .tor-label {
        height: 50px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        font: 500 1.1em Lato;
        background-color: #fafafa;
    }

    .tor-list-container {
        min-height: 30px;
        margin: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .to-be-completed-text {
            margin-top: 10px;
            margin-left: 10px;
            color: grey;
        }

        .tor-edit {
            height: 28px;
            width: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $sugarbook-main-color;
            border-radius: 25px;
            cursor: pointer;
            background-color: #f4ecec;

            .edit-icon {
                font-size: 20px;
                color: $sugarbook-main-color;
            }
        }

        .tor-add {
            height: 28px;
            width: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $sugarbook-main-color;
            border-radius: 25px;
            background-color: #f4ecec;

            .add-icon {
                color: $sugarbook-main-color;
            }
        }
    }

    // Utility CSS
    .blur {
        filter: blur(4px);
        background-color: white !important;
    }
}