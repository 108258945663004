#profile-view-terms-of-relationship-subcomponent {

    .padding-container {
        padding: 20px;
    }

    .tor-label {
        font: 600 1.1em Lato;
        color: #333;
    }

    .tor-list-container {
        width: 100%;
        padding-top: 15px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
}