#livestream-video-player-subcomponent {
    overflow: hidden;
    background-color: black;

    #live-video {
        height: 100%;
        width: 100%;
        // padding-bottom: 56.25%;
        // object-fit: contain;
        object-fit: cover;
    }
}

// Utility CSS
.desktop-video-player {
    height: calc(100% - 20px);
    margin: 10px 0;
    border-radius: 15px;
}

.mobile-video-player {
    height: 100%;
}

.maintain-aspect-ratio {
    padding-bottom: 56.25%;
}

.video-covered {
    object-fit: cover;
}

// Responsive Design
@media (max-width: 720px) {
    #livestream-video-player-subcomponent {

        #live-video {
            object-fit: cover;
        }
    }
}