#profile-view-indepth-info-subcomponent {

    .padding-container {
        padding: 20px 20px 100px 20px;
    }

    .detail-information-container {
        columns: 2;

        .information-container {
            margin-bottom: 20px;
            line-height: 1.6;

            .category-label {
                font: 600 1.1em Lato;
            }

            .data-label {
                color: #676767;
            }
        }
    }

    .page-detail-information-container {
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .indepth-item-container {
            display: flex;

            .indepth-label {
                flex: 1;
            }

            .indepth-data {
                flex: 1;
                font: 600 1em Lato;
            }
        }
    }
}

// Utility CSS
.apply-fix-width {
    width: 300px;
}