#livestream-loading-overlay-subcomponent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 30;
    background: radial-gradient(110.12% 64.72% at 67.38% 57.36%, rgb(135, 16, 16) 0%, rgb(72, 17, 17) 46.88%, rgb(71, 18, 18) 59.37%, rgb(106, 19, 19) 100%);

    .spinner-container {}

    .loading-text {
        padding-top: 20px;
        text-align: center;
        font: 600 1.2em Lato;
        color: white;
    }

    .tips-container {
        padding: 15px 20px;
        position: absolute;
        bottom: 0;
        border-radius: 10px 10px 0 0;
        font: 500 0.8em Lato;
        background-image: linear-gradient(to right, #231818 0%, #2C2323 20%, #231818 40%, #231818 100%);
        color: white;
    }
}