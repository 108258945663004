@import "../../../global-style.scss";

#upgrade-payment-page {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F4F4F4;

    .max-width-container {
        height: 100%;
        max-width: 500px;

        .padding-container {
            height: calc(100% - 60px - 20px); // 60px is the height pay button // 20px is padding
            padding: 10px 20px;
            display: flex;
            flex-direction: column;
            gap: 25px;
            overflow: auto;

            .top-container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .payment-method-label {
                    font: 600 1em Lato;
                }

                .trusted-image-container {
                    display: flex;
                    gap: 10px;

                    .discrete-badge {
                        height: 25px;
                    }

                    .security-badge {
                        height: 25px;
                    }

                    .ssl-badge {
                        height: 25px;
                    }
                }
            }

            .payment-provider-container {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }

            .secure-payment-container {
                padding: 10px;
                display: flex;
                border-radius: 10px;
                background-color: white;

                .lock-icon-container {
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .lock-icon {
                        width: 30px;
                    }
                }

                .secure-payment-description {
                    width: calc(100% - 110px);
                    padding-left: 10px;
                    display: flex;
                    flex-direction: column;
                    gap: 3px;

                    .secure-payment-label {
                        font: 600 1em Lato;
                    }

                    .secure-payment-body {
                        font: 500 0.9em Lato;
                        color: #7C7C7C;
                    }
                }

                .radio-button-container {
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .radio-button-outer {
                        height: 14px;
                        width: 14px;
                        padding: 3px;
                        border: 3px solid #E5E5E5;
                        border-radius: 50px;

                        .radio-button-inner {
                            height: 100%;
                            width: 100%;
                            border-radius: 50px;
                            background-color: $sugarbook-main-color
                        }
                    }
                }
            }

            .card-details-container {}

            .we-accept-label {
                text-align: center;
                font: 600 1.1em Lato;
            }

            .credit-card-company-image-container {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 8px;

                .visa-logo {
                    height: 30px;
                }

                .mastercard-logo {
                    height: 30px;
                }

                .maestro-logo {
                    height: 30px;
                }

                .electron-logo {
                    height: 30px;
                }

                .discover-logo {
                    height: 30px;
                }

                .jcb-logo {
                    height: 30px;
                }

                .diners-logo {
                    height: 30px;
                }

                .bleue-logo {
                    height: 30px;
                }
            }

            .amex-notice {
                text-align: center;
                font: 400 1em Lato;
                color: #7C7C7C;
            }

            .agreement-checkbox-container {

                .checkbox {
                    color: rgba(0, 0, 0, 0.6);

                    .MuiTypography-root {
                        font: 400 0.9em/1.4 Lato;
                    }
                }
            }

            .billed-today-info {
                text-align: center;
                font: 500 1em Lato;

                span {
                    font: 600 1em Lato;
                }
            }

            .billed-info {
                text-align: center;
                font: 500 0.8em Lato;
                color: #7C7C7C;

                .doublesize {
                    font: 600 1.2em Lato;
                }
            }

            .cancellation-info {
                padding: 0 0 15px 0;
                text-align: center;
                font: 500 0.8em Lato;
                color: #7C7C7C;
            }
        }

        .pay-button {
            height: 60px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font: 600 1.4em Lato;
            background: linear-gradient(132.82deg, #3A0101 0%, #7C0E0E 100%);
            color: white;
            transition: all 1s;
        }

        // Utility CSS
        .disabled-button {
            cursor: not-allowed;
            background: #D0D0D0;
            color: #B1B1B1;
        }
    }
}