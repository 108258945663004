@import "../../../global-style.scss";

#facebook-login-button-subcomponent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    .facebook-logo-container {
        display: flex;

        .facebook-logo {
            max-width: 16px;
        }
    }

    .facebook-login-label {
        font: 500 1.1em Lato;
        color: $sugarbook-default-text-color;
    }
}