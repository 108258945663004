@import "../../../global-style.scss";

#detail-signup-page {
    height: 100%;

    .max-width-container {
        height: 100%;
        width: 80%;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .steps-label {
            margin-bottom: 20px;
            text-align: center;
            font-size: 16px;
            color: #7c7c7c;
        }

        .step-description-label {
            padding-bottom: 20px;
            text-align: center;
            font-size: 26px;
            font-weight: 700;
        }

        .form-field-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 15px;

            .flex-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            .occupation-form-field-container {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                .occupation-form-field {
                    width: 100%;
                }
            }

            .education-form-field-container {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                .education-form-field {
                    width: 100%;
                }
            }

            .relationship-form-field-container {
                width: 45%;

                .relationship-form-field {
                    width: 100%;
                }
            }

            .children-form-field-container {
                width: 45%;

                .children-form-field {
                    width: 100%;
                }
            }

            .smoking-form-field-container {
                width: 45%;

                .smoking-form-field {
                    width: 100%;
                }
            }

            .drinking-form-field-container {
                width: 45%;

                .drinking-form-field {
                    width: 100%;
                }
            }
        }

        .button-container {
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .next-button {
                height: 45px;
                margin-top: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 25px;
                text-decoration: none;
                text-transform: capitalize;
                font: 500 1.1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .disabled-button {
                cursor: not-allowed;
                background-color: rgba(0, 0, 0, 0.12);
                color: rgba(0, 0, 0, 0.26);
            }

            .skip-button {
                text-decoration: none;
                text-transform: capitalize;
                font: 500 0.8em Lato;
                color: rgb(124, 124, 124);
            }
        }
    }
}

// Responsive Design
@media (max-height: 700px) {
    #detail-signup-page {
        .max-width-container {
            justify-content: unset;
        }
    }
}