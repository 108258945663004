@import "../../../../global-style.scss";

#add-payment-method-dialog {
    height: 100%;
    max-width: 720px;

    .dialog-header {
        padding: 20px 20px 0 20px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                font-size: 30px;
            }
        }
    }

    .padding-container {
        height: calc(100% - 93px);
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        overflow: auto;

        .coin-package-selected-container {
            display: flex;
            align-items: center;

            .sb-coin-icon-container {
                width: 50px;
                display: flex;

                .sb-coin-icon {
                    height: 35px;
                }
            }

            .coin-amount {
                flex: 1;
                font: 600 1em Lato;
            }

            .payment-amount {
                width: 100px;
                font: 600 1.2em Lato;
            }
        }

        .payment-methods-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .payment-method-container {
                min-height: 115px;
                // padding: 15px;
                display: flex;
                border-radius: 20px;
                background-color: #F6F6F6;

                .payment-icon-container {
                    width: 90px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .lock-icon {
                        height: 40px;
                    }

                    .payment-icon {}
                }

                .payment-description-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 1;
                    gap: 5px;

                    .payment-title {
                        font: 600 1em Lato;
                        color: #4A4A4A;
                    }

                    .payment-description {
                        font: 500 0.8em Lato;
                        color: #7C7C7C;
                    }
                }

                .select-container {
                    width: 80px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .select-payment-container {
                        height: 18px;
                        width: 18px;
                        border: 5px solid #E5E5E5;
                        border-radius: 50px;
                    }
                }
            }
        }

        .we-accept-label {
            text-align: center;
            font: 600 1.1em Lato;
        }

        .credit-card-company-image-container {
            display: flex;
            justify-content: center;
            gap: 8px;

            .visa-logo {
                height: 30px;
            }

            .mastercard-logo {
                height: 30px;
            }

            .maestro-logo {
                height: 30px;
            }

            .electron-logo {
                height: 30px;
            }
        }

        .agreement-checkbox-container {

            .checkbox {
                color: rgba(0, 0, 0, 0.6);

                .MuiTypography-root {
                    font: 400 0.9em/1.4 Lato;
                }
            }
        }

        .discretion-container {
            padding: 10px 0px;
            display: flex;
            flex-direction: column;
            gap: 7px;
            border-radius: 20px;
            color: rgb(51, 51, 51);

            .discretion-head {
                font: 500 0.8em Lato;
                color: #7C7C7C;
            }

            .discretion-body {
                font: 600 0.8em Lato;
            }
        }

        .pay-button {
            height: 47px;
            width: 100%;
            min-height: 47px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            cursor: pointer;
            font: 600 1.1em Lato;
            // background: linear-gradient(132.82deg, #3A0101 0%, #7C0E0E 100%);
            background-color: $sugarbook-main-color;
            color: white;
            transition: all 0.5s;
        }

        // Utility CSS
        .disabled-button {
            cursor: not-allowed;
            background-color: #D0D0D0;
            color: #B1B1B1;
        }
    }
}