@import "../../../../global-style.scss";

#preview-photo-dialog {
    height: 100%;
    display: flex;
    flex-direction: column;

    .dialog-header {
        padding: 20px;
        display: flex;
        justify-content: space-between;

        .close-button-container {
            cursor: pointer;

            .close-button {
                height: 25px;
            }
        }

        .delete-button-container {
            cursor: pointer;

            .delete-button {
                height: 25px;
            }
        }
    }

    .profile-photo-container {
        height: calc(90% - 68px);
        display: flex;

        .previous-button-container {
            width: 40px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .previous-button {
                cursor: pointer;
            }
        }

        .profile-photo {
            border-radius: 15px;
            object-fit: contain;
        }

        .forward-button-container {
            width: 40px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .next-button {
                cursor: pointer;
            }
        }
    }

    .set-profile-photo-container {
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;

        .set-profile-photo-button {
            padding: 10px 20px;
            border-radius: 25px;
            background-color: $sugarbook-main-color;
            color: white;
        }
    }

    // Utility CSS
    .default-button {
        color: white;
    }

    .transparent-button {
        color: transparent;
    }

    .dynamic-width {
        width: calc(100% - 48px);
    }

    .fixed-width {
        width: 520px;
    }
}