@import "../../../../global-style.scss";

#buy-coin-dialog {
    // height: 300px;
    max-width: 280px;

    .dialog-header {
        padding: 20px 20px 0 20px;
        display: flex;
        justify-content: flex-end;

        .close-button-container {
            cursor: pointer;

            .close-button {
                width: 25px;
            }
        }
    }

    .padding-container {
        height: calc(100% - 40px);
        padding: 10px 20px 40px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;

        .sb-coin-icon-container {
            display: flex;
            justify-content: center;

            .sb-coin-icon {
                height: 60px;
            }
        }

        .subheader {
            padding: 0 20px;
            text-align: center;
            font: 700 1.2em Lato;

            .sb-coin-icon-span-container {
                padding-left: 5px;

                .sb-coin-icon {
                    height: 20px;
                    position: relative;
                    top: 2px;
                }
            }
        }

        .text {
            text-align: center;
            font: 500 1em Lato;
        }

        .buttons-container {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .pay-button {
                height: 40px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px solid $sugarbook-main-color;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                background-color: $sugarbook-main-color;
                color: white;
            }

            .different-card-button {
                height: 40px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px solid $sugarbook-main-color;
                border-radius: 25px;
                cursor: pointer;
                font: 600 1em Lato;
                color: $sugarbook-main-color;
            }
        }
    }
}