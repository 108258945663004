#chat-section-following-element-subcomponent {
    padding: 0 0 0 15px;
    display: flex;

    .profile-picture-container {
        width: 45px;
        display: flex;
        align-items: center;

        .profile-picture {
            height: 30px;
            width: 30px;
        }
    }

    .chat-info-container {
        width: 88%;
        display: flex;
        align-items: center;
        font: 700 0.9em/1.4 Lato;

        .livestreaming-achievement-badge-container {
            display: flex;
            align-items: center;
            gap: 3px;

            .livestreaming-achievement-badge {
                height: 13px;
            }
        }

        .levelling-badge-container {
            padding-top: 3px;
            display: flex;

            .levelling-badge {
                height: 13px;
            }
        }
    }
}

.yellow {
    color: #FFE18C;
}

.green {
    color: rgb(80 179 67/1);
}